import { UmbracoFilterSection, UmbracoTags } from '@shared/types';
import {
  ALGOLIA_URL_PARAMS,
  getUrlToSet,
  IAlgoliaRefinementListData,
  IChosenFilterItemsResultData,
  isActiveRefinement,
} from 'utils/algoliaFilters';
import { getRemovedParamFromLocationQueryString, setUrl } from 'utils/browser';

const getChosenFilterItems = (
  sections: UmbracoFilterSection.IData[] | null,
  refinementList?: IAlgoliaRefinementListData
): IChosenFilterItemsResultData => {
  const data: IChosenFilterItemsResultData = {
    chosenFilterItems: [],
    chosenFilterIds: [],
    chosenSectionIds: [],
  };

  if (!refinementList || !sections) {
    return data;
  }

  sections.forEach((section: UmbracoFilterSection.IData) => {
    section.filterItems.forEach((item: UmbracoTags.IProductTag) => {
      if (!isActiveRefinement(refinementList, String(item.id), section.attributeForFaceting)) {
        return;
      }

      data.chosenFilterItems.push({
        title: item.title,
        itemId: item.id,
        sectionId: section.attributeForFaceting,
      });
      data.chosenFilterIds.push(String(item.id));

      if (!data.chosenSectionIds.includes(section.attributeForFaceting)) {
        data.chosenSectionIds.push(section.attributeForFaceting);
      }
    });
  });

  return data;
};

const getDefaultFiltersParams = (lang: string, additionalFilterParams?: string): string =>
  `lang:${lang}${additionalFilterParams || ''}`;

const setFilteredUrlParams = (
  sections: UmbracoFilterSection.IData[] | null,
  refinementList?: IAlgoliaRefinementListData
): void => {
  const { chosenFilterItems } = getChosenFilterItems(sections, refinementList);
  let url = getUrlToSet(chosenFilterItems);

  if (!url) {
    url = getRemovedParamFromLocationQueryString(ALGOLIA_URL_PARAMS.filterSection);
  }

  setUrl(url || '');
};

const Helpers = {
  getChosenFilterItems,
  getDefaultFiltersParams,
  setFilteredUrlParams,
};

export default Helpers;
