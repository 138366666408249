import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import FocusTrap from 'focus-trap-react';

import { parseButtonComposition, parseFilterSections } from 'utils/parsers';
import {
  removeItemInRefinementList,
  IAlgoliaSearchStateData,
  DefaultSearchState,
  HitsPerPage,
  getSavedSelectionDataToSet,
} from 'utils/algoliaFilters';

import { Container } from 'layout';

import FiltersToggler from 'components/AlgoliaFilters/FiltersToggler';
import FilterPanel from 'components/AlgoliaFilters/FilterPanel';
import AlgoliaCustomHits from 'components/AlgoliaCustomHits';

import './AlgoliaFilters.scss';
import { IPropsAlgoliaFilters } from './models';
import Helpers from './helpers';

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID as string,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY as string
);

const AlgoliaFilters: FC<IPropsAlgoliaFilters> = ({
  isSmallDevice,
  filtersStructure,
  indexName,
  saveAlgoliaHitsResponse,
  handleAlgoliaFiltersUsed,
  handleHitsResponseActivated,
  isHitsResponseActivated,
  lang,
  showResultsScrollTarget,
}): ReactElement | null => {
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [searchState, setSearchState] = useState<IAlgoliaSearchStateData>(DefaultSearchState);

  const filtersStructureData = filtersStructure?.[0]?.properties;
  const filterSections = useMemo(
    () => parseFilterSections(filtersStructureData.filterSections),
    [filtersStructureData.filterSections]
  );

  const savedSelectionData = getSavedSelectionDataToSet();
  useEffect(() => {
    if (!savedSelectionData) {
      setSearchState(DefaultSearchState);
    }
  }, [savedSelectionData]);

  const { chosenFilterItems, chosenFilterIds } = useMemo(
    () => Helpers.getChosenFilterItems(filterSections, searchState.refinementList),
    [filterSections, searchState.refinementList]
  );

  const handleActiveFiltersIdsOnLoad = useCallback(() => {
    const newSearchState = getSavedSelectionDataToSet();
    if (!newSearchState) {
      return;
    }
    handleAlgoliaFiltersUsed(true);
    setSearchState(newSearchState);
  }, []);

  useEffect(() => {
    if (!filterSections) {
      return;
    }

    handleActiveFiltersIdsOnLoad();
  }, [filterSections]);

  const handleSidebarOpen = useCallback(() => {
    setSidebarOpen((oldValue: boolean) => !oldValue);
  }, []);

  const handleResetSelectionData = useCallback(() => {
    if (chosenFilterItems.length) {
      setSearchState({ ...DefaultSearchState });
    }
    Helpers.setFilteredUrlParams(filterSections);
  }, [chosenFilterItems.length]);

  const checkAlgoliaFiltersUsage = useCallback((newSearchState: IAlgoliaSearchStateData) => {
    if (!newSearchState?.refinementList) {
      handleAlgoliaFiltersUsed(false);

      return;
    }

    if (Object.keys(newSearchState.refinementList).length) {
      handleAlgoliaFiltersUsed(true);

      return;
    }

    handleAlgoliaFiltersUsed(false);
  }, []);

  const handleOnSearchStateChange = useCallback((newSearchState: IAlgoliaSearchStateData) => {
    Helpers.setFilteredUrlParams(filterSections, newSearchState.refinementList);
    checkAlgoliaFiltersUsage(newSearchState);
    setSearchState(newSearchState);
  }, []);

  const handleRemoveSelectionData = useCallback(
    (sectionId: string, itemId: number) => () => {
      setSearchState((oldState: IAlgoliaSearchStateData) => {
        const newSearchState = {
          ...oldState,
          refinementList: {
            ...oldState.refinementList,
          },
        };

        const sectionData = removeItemInRefinementList(
          oldState?.refinementList?.[sectionId],
          String(itemId)
        );

        if (!sectionData) {
          delete newSearchState.refinementList[sectionId];
        } else {
          newSearchState.refinementList[sectionId] = sectionData;
        }

        Helpers.setFilteredUrlParams(filterSections, newSearchState.refinementList);

        return newSearchState;
      });
    },
    []
  );

  const togglerMainData = parseButtonComposition(filtersStructureData.filtersTogglerCTATitleMain);

  return searchClient && filtersStructureData ? (
    <div data-test="AlgoliaFilters">
      <InstantSearch
        indexName={indexName}
        searchClient={searchClient}
        refresh
        stalledSearchDelay={500}
        searchState={searchState}
        onSearchStateChange={handleOnSearchStateChange}
      >
        <Configure
          filters={Helpers.getDefaultFiltersParams(lang)}
          hitsPerPage={HitsPerPage}
          analytics={false}
          distinct
          maxValuesPerFacet={HitsPerPage}
        />
        <Container className="algolia-filters">
          {togglerMainData ? (
            <FiltersToggler
              filtersNumber={chosenFilterIds.length}
              handleSidebarOpen={handleSidebarOpen}
              titleMain={togglerMainData.label}
              buttonAriaLabel={togglerMainData.ariaLabel}
              titleSecondary={filtersStructureData.filtersTogglerCTATitleSecondary}
            />
          ) : null}
        </Container>

        <FocusTrap>
          <FilterPanel
            isSidebarOpen={isSidebarOpen}
            handleSidebarOpen={handleSidebarOpen}
            filtersStructureData={filtersStructureData}
            isSmallDevice={isSmallDevice}
            showResultsScrollTargetId={showResultsScrollTarget}
            handleResetSelectionData={handleResetSelectionData}
            handleRemoveSelectionData={handleRemoveSelectionData}
            chosenFilterIds={chosenFilterIds}
            filterSections={filterSections}
            filtersNumber={chosenFilterIds.length}
          />
        </FocusTrap>

        <AlgoliaCustomHits
          saveAlgoliaHitsResponse={saveAlgoliaHitsResponse}
          handleHitsResponseActivated={handleHitsResponseActivated}
          isHitsResponseActivated={isHitsResponseActivated}
        />
      </InstantSearch>
    </div>
  ) : null;
};

export default AlgoliaFilters;
