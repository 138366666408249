import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import Layout from 'layout/Layout';
import Container from 'layout/Container';
import useScreenRecognition from 'hooks/useScreenRecognition';
import useAlgoliaResponse from 'hooks/useAlgoliaResponse';

import TitleWithImage from 'common/TitleWithImage';
import BreadCrumbs from 'common/BreadCrumbs';
import ProductsList from 'components/ProductsList';
import AlgoliaFilters from 'components/AlgoliaFilters';
import DisclaimerMessage from 'common/DisclaimerMessage';
import ProductsFinder from 'components/ProductsFinder';
import BuyPromoBanner from 'components/BuyPromoBanner';

import { IPropsProductsListPage } from './models';
import './ProductsListPage.scss';
import './ShadowProductsListPage.scss';

export const SHOW_RESULTS_SCROLL = 'showResultsScrollTarget';

const ProductsListPage: FC<IPropsProductsListPage> = ({
  data: { pageData, defaultProducts },
  pageContext: { breadcrumbs, lang },
}) => {
  const { isMobile, isTablet } = useScreenRecognition();
  const isSmallDevice = isMobile || isTablet;
  const { seo, defaultCompositions } = pageData;

  const {
    itemsToRender,
    saveAlgoliaHitsResponse,
    handleAlgoliaFiltersUsed,
    handleHitsResponseActivated,
    isHitsResponseActivated,
  } = useAlgoliaResponse(defaultProducts.nodes);

  return (
    <Layout defaultCompositions={defaultCompositions} seo={seo}>
      <div data-test="ProductsListPage" className="products-list-page">
        <Container>
          <BreadCrumbs breadcrumbs={breadcrumbs} />
        </Container>
        {pageData?.startQuiz?.[0] ? (
          <div className="products-list-page__products-finder">
            <Container>
              <ProductsFinder
                startQuiz={pageData.startQuiz}
                productQuizData={defaultCompositions.productQuiz}
                ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
                ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
                lang={lang}
              />
            </Container>
          </div>
        ) : null}

        <TitleWithImage
          title={pageData.title}
          titleImage={pageData.titleImage}
          titleImageAlt={pageData.titleImageAlt}
          id={SHOW_RESULTS_SCROLL}
        />

        {pageData?.filtersStructure?.[0] ? (
          <div>
            <AlgoliaFilters
              indexName={`${process.env.GATSBY_ALGOLIA_INDEX_PREFIX}-products`}
              isSmallDevice={isSmallDevice}
              filtersStructure={pageData.filtersStructure}
              saveAlgoliaHitsResponse={saveAlgoliaHitsResponse}
              handleAlgoliaFiltersUsed={handleAlgoliaFiltersUsed}
              handleHitsResponseActivated={handleHitsResponseActivated}
              isHitsResponseActivated={isHitsResponseActivated}
              lang={lang}
              showResultsScrollTarget={SHOW_RESULTS_SCROLL}
            />
          </div>
        ) : null}

        <ProductsList
          products={itemsToRender}
          sorryMessage={pageData.sorryMessage}
          showMoreCtaButton={pageData.showMoreCtaButton}
          limitOnPageDesktop={pageData.limitOnPageDesktop}
          limitOnPageMobile={pageData.limitOnPageMobile}
          isSmallDevice={Boolean(isSmallDevice)}
          sortingProducts={pageData.sortingProducts}
          addClassName={classNames({
            'without-algolia-filters': !pageData?.filtersStructure?.[0],
          })}
        />
        {pageData.buyPromoBanner?.length ? (
          <BuyPromoBanner
            buyPromoBanner={pageData.buyPromoBanner}
            ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
            ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
            dir={defaultCompositions.siteSettings.dir[0]}
          />
        ) : null}

        {pageData.warningMessage ? (
          <Container>
            <DisclaimerMessage
              content={pageData.warningMessage}
              isDesktop={false}
              className="products-list-page__disclaimer-message"
            />
          </Container>
        ) : null}
      </div>
    </Layout>
  );
};

export const query = graphql`
  fragment FragmentFiltersStructure on TFiltersStructure {
    properties {
      title
      filtersTogglerCTATitleMain {
        ...FragmentButton
      }
      filtersTogglerCTATitleSecondary
      closeButton {
        ...FragmentButton
      }
      showResultsButton {
        ...FragmentButton
      }
      showResultsButtonTitleSecondary
      showResultsButtonTitleSecondaryMultiple
      clearAllButton {
        ...FragmentButton
      }
      filterSections {
        properties {
          tagsTaxonomy {
            properties {
              taxonomy
            }
          }
          isOpen
          title
          filterItems {
            id
            title
          }
        }
      }
    }
  }

  query ($link: String!, $lang: String!, $productsLinks: [String]) {
    pageData: productsList(link: { eq: $link }) {
      seo {
        ...FragmentSeo
      }
      defaultCompositions {
        ...FragmentDefaultCompositions
      }
      title
      titleImage {
        ...FragmentGatsbyImage
      }
      titleImageAlt
      sorryMessage
      showMoreCtaButton {
        ...FragmentButton
      }
      limitOnPageDesktop
      limitOnPageMobile
      filtersStructure {
        ...FragmentFiltersStructure
      }
      warningMessage
      startQuiz {
        ...FragmentStartQuizSection
      }
      buyPromoBanner {
        ...FragmentBuyPromoBanner
      }
      sortingProducts {
        ...FragmentSortingProducts
      }
    }
    defaultProducts: allProduct(filter: { link: { in: $productsLinks }, lang: { eq: $lang } }) {
      nodes {
        ...FragmentProductCard
      }
    }
  }
`;

export default ProductsListPage;
